@import "../../football/styles/homepage";

body {
	.homepage .container.media_partners {
		@import "../../base/styles/layout/homepage/media-partners-presentation-type";
	}

	.modal.video .modal-dialog {
		max-width: 600px;

		.modal-body {
			text-align: center;
		}
	}
}